<template>
  <b-card>
    <b-table
      ref="table"
      :items="getTvTutorials"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No tutorials found"
      show-empty
    >
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="$can('update','global-TVExplanation')"
            v-b-modal.edit-modal
            variant="outline-primary"
            @click="setTutorial(item)"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
export default {
  name: 'TVTutorialTable',
  props: {
    getTvTutorials: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#' },
        { key: 'title', label: 'Element Title' },
        { key: 'body', label: 'Description' },
        { key: 'status', formatter: v => (v ? 'Active' : 'Inactive') },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    setTutorial(tutorial) {
      this.$emit('update:tvTutorial', tutorial)
    },
  },
}
</script>
<style lang="scss">

</style>
