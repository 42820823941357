<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      ref="myForm"
      class="p-2"
      @submit.prevent
    >
      <validation-provider
        #default="validationContext"
        name="Body"
        rules="max:500"
      >
        <b-form-group
          label="Body"
          label-for="body"
        >
          <b-textarea
            id="body"
            v-model="tvTutorialProxy.body"
            placeholder="Body"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        label="Status"
        label-for="status"
      >
        <b-checkbox
          id="status"
          v-model="tvTutorialProxy.status"
        />
      </b-form-group>
      <submit-button
        :submit="editTutorial"
        :handle-submit="handleSubmit"
      />
    </b-form>
  </validation-observer>
</template>
<script>
import formValidation from '@/@core/comp-functions/forms/form-validation'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'EditTutorial',
  components: { SubmitButton },
  props: {
    tvTutorial: { type: Object, default: () => {} },
  },
  computed: {
    tvTutorialProxy() {
      return { ...this.tvTutorial }
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert } = handleAlerts()
    return { getValidationState, successfulOperationAlert }
  },
  methods: {
    editTutorial() {
      this.$activities.put(`/internalops/tv/explanation/${this.tvTutorial.id}`, this.tvTutorialProxy).then(() => {
        this.successfulOperationAlert('Tutorial is updated successfully')
        this.$bvModal.hide('edit-modal')
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$emit('refresh')
    },
  },
}
</script>
<style lang="scss">

</style>
