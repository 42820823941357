<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4">
          <b-form-select
            v-model="moduleId"
            :options="moduleOptions"
            text-field="title"
            value-field="id"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select Module --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <tv-tutorial-table
      ref="parentTable"
      v-bind="{
        getTvTutorials,
        pagination
      }"
      :tv-tutorial.sync="tvTutorial"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

    <div class="d-flex m-2">
      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Tutorial"
        hide-footer
      >
        <edit-tutorial
          :tv-tutorial.sync="tvTutorial"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import TvTutorialTable from '@/common/components/AppContent/tv-tutorial/TVTutorialTable.vue'
import EditTutorial from '@/common/components/AppContent/tv-tutorial/EditTutorial.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'TvTutorial',
  components: {
    Pagination,
    TvTutorialTable,
    EditTutorial,
    SearchButton,
  },
  data() {
    return {
      searchQuery: null,
      tvTutorial: {},
      moduleOptions: [],
      moduleId: null,
    }
  },
  created() {
    this.getModules()
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getTvTutorials() {
      const promise = this.$activities.get('/internalops/tv/explanation', {
        params: {
          moduleId: this.moduleId,
        },
      })

      return promise.then(res => {
        const items = res.data.data

        this.pagination.totalRows = res.data.total
        return items || []
      }).catch(() => [])
    },
    getModules() {
      this.$activities.get('/internalops/tv/explanation-modules').then(res => {
        this.moduleOptions = res.data.data
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
